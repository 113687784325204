/* You can add global styles to this file, and also import other style files */

@import "variables";

@import "~bootstrap/scss/bootstrap";

body {
//  font-size: 1.1rem;
}

.color-primary {
  color: $primary;
}

.text-alert {
  font-style: italic;
  font-weight: bold;
  color: red;
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: $primary;
}
.heading span {
  font-size: 6vw;
  font-weight: bolder;
  height: fit-content;
  width: 100%;
  text-align: center;
  color: white;
}

.btn-primary {
  min-width: 100px;
}
.btn-primary:not(:disabled):not(.disabled):hover,
.btn-primary:not(:disabled):not(.disabled):active, {
  background-color: white;
  color: $primary;
  border-color: $primary;
}

.info-section {
  border: 2px solid $primary;
  border-radius: $border-radius;
  width: 100%;
//  span {
//    font-size: 1em;
//    width: 100%;
//  }
}

.section-heading {
  display: flex;
  flex-direction: row;
  color: black;
  background-color: white;
  align-items: center;
  justify-content: center;
  border: 2px solid $primary;
  border-radius: $border-radius;
  span {
    font-size: max(2vw, 20px);
    font-weight: bolder;
  }
}

.step-heading {
  display: flex;
  flex-direction: row;
  color: white;
  background-color: $primary;
  border-radius: $border-radius;
  padding: .5em 0 .5em 0;
  span {
    font-size: max(2vw, 20px);
  }
}

span.emphasis {
  display: inline;
  font-weight: bolder;
  text-transform: uppercase;
}

.border-bottom-dashed {
  border-bottom-style: dashed;
  border-bottom-width: 1px;
}
.border-right-dashed {
  border-right-style: dashed;
  border-right-width: 1px;
}
.border-top-dashed {
  border-top-style: dashed;
  border-top-width: 1px;
}

// Common Label styles

div.nutrition-label {
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  letter-spacing: -.05rem;
  width: 30rem;
}

span.label-item-amount {
  float:right;
  text-align: right;
  font-weight: bold;
  padding-right: 1.25rem;
  width: 40%;
}

span.label-item-highlight {
  background-color: lightgray;
}

.label-annotation-minimum {
  background-color:rgba(0, 128, 0, .5);
  padding: 0 0.5rem 0 0.5rem;
}

.label-annotation-maximum {
  background-color:rgba(205, 92, 72, .8);
  padding: 0 0.5rem 0 0.5rem;
}

@media all {
  .printHeader {
    display: none;
  }
  .printFooter {
    display: none;
  }

  .page-break	{ display: none; }

  .no-print {}
}

@media print {
  .page-break	{ display: block; page-break-before: always; }

  .no-print {
    display: none !important;
  }

  .printHeader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .printFooter {
    display: flex;
    align-items: center;
  }

  .heading span {
    color: black;
  }

  .section-heading {
    display: none;
  }

  .step-heading {
    display: none;
  }

}
